import request from 'app/http'

const postConsult = (params) => {
  return request.post('/feedback/add/buy/contact', params)
}

const getFeedbackList = (params) => {
  return request.get('/feedback/get/feedback', params)
}

const postFeedbackAdd = (params) => {
  return request.post('/feedback/add/feedback', params)
}

const deleteFeedback = (params) => {
  return request.post('/feedback/delete', params)
}

const getFeedbackCount = () => {
  return request.get('/feedback/get/remain/times')
}

export {
  postConsult,
  getFeedbackList,
  postFeedbackAdd,
  deleteFeedback,
  getFeedbackCount
}
