<template>
  <div
    class="feedback-form__wrapper"
    v-loading="isLoading"
    :element-loading-text="$t('loading')"
    element-loading-background="var(--report-analyse-mask)"
  >
    <div class="feedback-title__wrap">
      <span class="feedback-title__text">{{$t('feedback.title')}}</span>
      <span class="feedback-title__desc">
        {{$t('feedback.remain')}}
        <span class="title-desc__count">{{configData.used}}/{{configData.count}}</span>
      </span>
    </div>
    <div class="feedback-form__wrap">
      <el-form
        ref="formRef"
        :model="formModel"
        :rules="rules"
        label-width="120px"
        :status-icon="false"
      >
        <el-form-item :label="$tLabel($t('feedback.type'))" prop="category">
          <el-radio-group v-model="formModel.category">
            <el-radio label="result">{{$t('feedback.analysis_result')}}</el-radio>
            <el-radio label="product">{{$t('feedback.product_feature')}}</el-radio>
            <el-radio label="other">{{$t('feedback.other_info')}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$tLabel($t('feedback.content'))" prop="descrtption">
          <el-input :placeholder="$t('feedback.content_placeholder')" type="textarea" show-word-limit maxlength="200" v-model.trim="formModel.descrtption" />
        </el-form-item>
        <el-form-item suffix-icon="" :label="$tLabel($t('consulting.email.label'))" prop="email">
          <el-autocomplete
            v-model="formModel.email"
            :disabled="!!emial"
            name="email"
            :fetch-suggestions="querySearchEmail"
            :input-style="{ width: '292px' }"
            show-word-limit
            maxlength="30"
            :placeholder="$t('consulting.email.placeholder')"
          />
          <!-- <el-input placeholder="请输入邮箱" :input-style="{ width: '292px' }" v-model="formModel.email" show-word-limit maxlength="30" /> -->
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm(formRef)">{{$t('action.confirm')}}</el-button>
          <el-button @click="resetForm(formRef)">{{$t('action.clear')}}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, defineEmits, watch, onMounted, computed } from 'vue'
import { isEmail } from '@/utils/validate'
import { message } from '@/hooks/useUI'
import { useState } from '@/hooks/useVuex'
import { postFeedbackAdd, getFeedbackCount } from '@/services/help'
import { t } from 'app/i18n'

const { userInfo } = useState('user', ['userInfo'])

const formModel = reactive({
  category: '',
  descrtption: '',
  email: userInfo.value.email || ''
})

const configData = ref({
  count: 3,
  used: 3
})

const emial = computed(() => {
  return userInfo.value.email
})

watch(() => formModel.email, (e) => {
  if (!emial.value) return
  setTimeout(() => {
    const dom = document.getElementsByName('email')[0]
    const reg = /^(.{1})(.+)(?=.{1}@)/g
    const value = e?.replace(reg, va => e[0] + '*'.repeat(va.length - 1))
    dom.value = value
  }, 100)
}, { immediate: true })

watch(userInfo, () => {
  formModel.email = userInfo.value.email
})

const formRef = ref()
const isLoading = ref(false)

const validateEmail = (rule, value, callback) => {
  if (value === '') {
    callback(new Error(t('consulting.email.placeholder')))
  } else if (!isEmail(value)) {
    callback(new Error(t('consulting.email.invalid')))
  } else {
    if (value.length > 30) {
      callback(new Error(t('consulting.email.length_limited')))
    } else {
      callback()
    }
  }
}
const rules = reactive({
  category: [
    { required: true, message: t('feedback.type_invalid') }
  ],
  email: [
    { validator: validateEmail, trigger: 'change' },
    { required: true, message: t('consulting.email.placeholder') }
  ],
  descrtption: [
    { required: true, message: t('feedback.content_invalid') }
  ]
})

const querySearchEmail = (queryString, cb) => {
  if (queryString.indexOf('@') > 0 || !queryString) {
    return cb([])
  }
  const results = [
    {
      value: queryString + '@163.com'
    },
    {
      value: queryString + '@sina.com'
    },
    {
      value: queryString + '@qq.com'
    },
    {
      value: queryString + '@126.com'
    },
    {
      value: queryString + '@vip.sina.cn'
    },
    {
      value: queryString + '@hotmail.com'
    },
    {
      value: queryString + '@gmail.com'
    },
    {
      value: queryString + '@sohu.com'
    },
    {
      value: queryString + '@yahoo.com'
    }
  ]
  cb(results)
}

onMounted(() => {
  getConfig()
})

const getConfig = () => {
  isLoading.value = true
  getFeedbackCount().then(res => {
    isLoading.value = false
    configData.value.used = configData.value.count - res.feedback_times
  }).catch(() => {
    isLoading.value = false
  })
}

const emit = defineEmits(['submitSuccess'])
const submitForm = (formEl) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      if (configData.value.used === 0) {
        message(t('consulting.no_remain'), 'error')
      } else {
        postFeedbackAdd(formModel).then((res) => {
          if (res.code) {
            message(res.message, 'error')
          } else {
            message(t('feedback.success'))
            resetForm(formEl)
            // 执行emit
            emit('submitSuccess')
            getConfig()
          }
        })
      }
    } else {
      return false
    }
  })
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
}

</script>
<style lang="scss" scoped>
@import '@/styles/common.scss';
.feedback-form__wrapper {
  padding: 0 24px 10px;
  .feedback-title__wrap {
    box-sizing: border-box;
    line-height: 50px;
    border-bottom: 1px solid var(--color-border-2);
    .feedback-title__text {
      font-size: 18px;
      color: var(--color-text-1);
      font-weight: 600;
    }
    .feedback-title__desc {
      font-size: 12px;
      margin-left: 21px;
      color: var(--color-text-2);
      .title-desc__count {
        color: var(--color-priamry);
      }
    }
  }
  .feedback-form__wrap {
    width: 784px;
    padding: 24px 0;
    :deep(.el-form-item) {
      margin-bottom: 24px;
    }
    // :deep(.el-input__count-inner) {
    //   color: var(--color-text-2);
    //   background-color: transparent;
    // }
    :deep(.el-input__count) {
      color: var(--color-text-2);
      background-color: transparent;
    }
  }
}
</style>
