<template>
  <el-pagination
    class="list-pagination"
    :hide-on-single-page="totalSize === 0"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="currentPage"
    :page-sizes="pageSizes"
    :page-size="pageSize"
    :layout="layout"
    popper-class="detect-operate"
    :total="computedTotalSize">
    <span class="el-pagination__total is-first">{{ customTotal && totalSize > 10000 ? $t('pagination.part', [totalSize]) : $t('pagination.total', [totalSize]) }}</span>
  </el-pagination>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Pagination',
  props: {
    totalSize: {
      type: Number,
      default () {
        return 0
      }
    },
    customTotal: {
      type: Boolean,
      default: false
    },
    pageSizes: {
      type: Array,
      default () {
        return [50, 100, 150, 200]
      }
    },
    current_page: {
      type: Number
    },
    hide_pageSize: {
      type: Boolean,
      default: false
    },
    jumper: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    current_page (newPage) {
      this.currentPage = newPage
    }
    // '$route'() {
    //   this.currentPage = this.curTab
    // }
  },
  computed: {
    layout () {
      const layout = this.jumper ? 'slot, prev, pager, next, jumper' : 'slot, prev, pager, next'
      return this.hide_pageSize ? layout : (layout + ', sizes')
    },
    computedTotalSize () {
      if (this.totalSize > 10000 && this.customTotal) {
        return 10000
      }
      return this.totalSize
    },
    ...mapGetters({
      curTab: 'report/curTab'
    })
  },
  data () {
    return {
      currentPage: this.current_page || 1,
      pageSize: this.pageSizes[0]
    }
  },
  methods: {
    handleSizeChange (size) {
      this.pageSize = size
      this.$emit('handleSizeChange', size)
    },
    handleCurrentChange (page) {
      this.currentPage = page
      this.$emit('handleCurrentChange', page)
    }
  }
}
</script>
<style lang="scss" scoped>
.list-pagination {
  :deep(.btn-prev) {
    margin-right: 1px;
  }
  :deep(.btn-next) {
    margin: 0 8px 0 1px !important;
  }
  :deep(.el-pagination__total) {
    margin-right: 8px !important;
  }
}
</style>
