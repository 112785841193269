<template>
  <div class="threat-wrapper">
    <submit-form @submitSuccess="handleChange" />
    <history-list ref="historyRef" v-if="loginState" />
    <backTo-top></backTo-top>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import historyList from './history-list.vue'
import submitForm from './submit-form.vue'

const historyRef = ref()
const store = useStore()
const loginState = ref(store.getters.isLogin)

const handleChange = () => {
  historyRef.value.handleSizeChange(1)
}
</script>
<style lang="scss" scoped>
@import '@/styles/common.scss';
.threat-wrapper {
  min-height: calc(100vh - $footer-height - $header-height);
}
</style>
