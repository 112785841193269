<template>
  <div
    class="feedback-history__wrapper"
    v-loading="isLoading"
    :element-loading-text="$t('loading')"
    element-loading-background="var(--report-analyse-mask)"
  >
    <div class="feedback-title__wrap">
      <span class="feedback-title__text">{{$t('feedback.history')}}</span>
    </div>
    <div class="feedback-table__wrap">
      <div class="action-wrap">
        <el-button :disabled="canDele" @click="deleDialogVisible = true">{{$t('feedback.delete')}}</el-button>
      </div>
      <el-table @sort-change="sortHandle" :data="tableData" style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55px" />
        <el-table-column prop="id" :label="$t('feedback.id')" width="230px" />
        <el-table-column prop="create_time" sortable :label="$t('feedback.create_time')" width="200px" />
        <el-table-column prop="category" :label="$t('feedback.type')" width="160px" />
        <el-table-column prop="content" :label="$t('feedback.label_content')" :show-overflow-tooltip="true">
        </el-table-column>
        <template #empty>
          <div class="empty">
            <sandNoData :text="$t('nodata')" />
          </div>
        </template>
      </el-table>
    </div>
  </div>
  <div class="pagination-wrap">
    <Pagination
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :totalSize="totalSize"
      :current_page="currentPage"
    />
  </div>
  <el-dialog
    v-model="deleDialogVisible"
    :title="$t('feedback.dialog.title')"
    width="470px"
  >
    <div class="delete-team__content">
      <svg-icon name="info"></svg-icon>
      <span style="line-height: 21px;">{{$t('feedback.dialog.attention')}}</span>
    </div>
    <template #footer>
      <span class="dialog-footer">

        <el-button type="primary" @click="deleDialogVisible = false">{{$t('dialog.cancel')}}</el-button>
        <el-button @click="deleListItem"
          >{{$t('dialog.delete.confirm')}}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, onMounted, computed, defineExpose, nextTick } from 'vue'
import dayjs from 'dayjs'
import { message } from '@/hooks/useUI'
import { getFeedbackList, deleteFeedback } from '@/services/help'
import Pagination from '@/components/common/Pagination'
import SandNoData from '@/components/report/sandNoData'
import { t } from 'app/i18n'

const categoryToStr = {
  result: t('feedback.analysis_result'),
  product: t('feedback.product_feature'),
  other: t('feedback.other_info')
}

const sortData = ref({
  sort_by_time: undefined
})

const tableData = ref([
])
const multipleSelection = ref([])
const isLoading = ref(false)
const totalSize = ref(0)
const deleDialogVisible = ref(false)
const pageSize = ref(50)
const currentPage = ref(1)

onMounted(() => {
  getData()
})

const handleSizeChange = (size) => {
  pageSize.value = size
  handleCurrentChange(1)
}
const handleCurrentChange = (page) => {
  currentPage.value = page
  getData()
}

const sortHandle = (column) => {
  // console.log(column, prop, order)
  if (column.prop === 'create_time') {
    sortData.value.sort_by_time = column.order === 'ascending' ? 'asc' : 'desc'
  } else {
    sortData.value.sort_by_time = undefined
  }
  nextTick(() => {
    getData()
  })
}

const handleSelectionChange = (val) => {
  multipleSelection.value = val
}

const canDele = computed(() => {
  return !(multipleSelection.value && multipleSelection.value.length)
})

const getData = () => {
  isLoading.value = true
  getFeedbackList({
    size: pageSize.value,
    page: currentPage.value
  }).then((res) => {
    isLoading.value = false
    totalSize.value = res.total
    tableData.value = res.feedback.map(item => {
      return {
        id: item.id,
        create_time: formatUTC(item.create_time),
        category: categoryToStr[item.category],
        content: item.content
      }
    })
  })
}
defineExpose({
  handleSizeChange
})
const deleListItem = () => {
  // 执行删除操作
  // 删除成功，重新获取数据
  deleteFeedback({
    feedback_ids: multipleSelection.value.map(item => item.id)
  }).then((res) => {
    if (res.code) {
      message(res.message, 'error')
    } else {
      message(t('action.delete'))
      handleCurrentChange(1)
    }
    deleDialogVisible.value = false
  })
}

const formatUTC = (time) => {
  return dayjs(time).format('YYYY-MM-DD HH:mm:ss')
}

</script>
<style lang="scss" scoped>
@import '@/styles/common.scss';
.feedback-history__wrapper {
   padding: 0 24px;
   max-width: 100%;
  .feedback-title__wrap {
    line-height: 37px;
    border-bottom: 1px solid var(--color-border-2);
    .feedback-title__text {
      font-size: 18px;
      color: var(--color-text-1);
    }
  }
  .feedback-table__wrap {
    padding-top: 20px;
    max-width: calc(100% - 1px);
    .action-wrap {
      padding-bottom: 8px;
    }
    :deep(.cell) {
      line-height: 32px;
    }
    :deep(th) {
      background-color: var(--color-bg-3);
    }
    // .item-content__wrap {
    //   float: left;
    //   max-width: 100%;
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    //   white-space: nowrap;
    // }
  }
  .empty {
    height: calc(100vh - 655px);
  }
}
.pagination-wrap {
  padding: 10px 3px 24px;
  text-align: right;
}
</style>

<style lang="scss">
.delete-team__content {
  display: flex;
  svg {
    margin-right: 8px;
    font-size: 20px;
    color:#fa8c16;
  }
}
.el-popper {
  max-width: 400px;
}
</style>
